import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { useUser } from '../UserContext'; 
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../loginRequest';

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const { user, setUser } = useUser();
    const { instance } = useMsal();

    const handleLogin = async () => {
        try {
            const loginResponse = await instance.loginPopup(loginRequest);
            instance.setActiveAccount(loginResponse.account);
            const account = loginResponse.account;
            const name = account.idTokenClaims?.name || account.username;
            const email = account.idTokenClaims?.email || account.username;
            
            setUser({
                id: account.localAccountId,
                displayName: name,
                email: email
            });
        } catch (error) {
            console.error('Login error:', error);
        }
    };

    const handleLogout = async () => {
        try {
            await instance.logoutRedirect();
            setUser(null);
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className="header">
            <div className="nav-top">
                <div className="navbar-left">
                    <div className="brand-container">
                        <div className="brand-icon"></div>
                        <Link className="navbar-logo" to="/">QARBY</Link>
                    </div>
                </div>

                <div className="navbar-center">
                    <ul>
                        {/* <li><Link to="/about">About</Link></li>
                        <li><Link to="/garage">Garage</Link></li> */}
                        {/* <li><Link to="/dealership-portal">Dealership Portal</Link></li>  */}
                        {/* <Route path="/dealershipdealership" element={<DealerPortal />} /> */}
                    </ul>
                </div>

                <div className="navbar-right">
                    <Link to="/AskOrBuy" className="get-started-btn">Get Started</Link>
                    <div className="auth-controls">
                        {user && user.id ? (
                            <>
                                <span className="welcome-message">Welcome, {user.displayName}!</span>
                                <button className="login-button" onClick={handleLogout}>Logout</button>
                            </>
                        ) : (
                            <button className="login-button" onClick={handleLogin}>Login</button>
                        )}
                    </div>
                    <div className="hamburger" onClick={toggleMenu}>
                        <span className="bar"></span>
                        <span className="bar"></span>
                        <span className="bar"></span>
                    </div>
                </div>
            </div>

            <nav className={`navbar-links-container ${menuOpen ? 'open' : ''}`}>
                <ul className="navbar-links">
                    <li><Link to="/" onClick={() => setMenuOpen(false)}>Home</Link></li>
                    <li><Link to="/about" onClick={() => setMenuOpen(false)}>About</Link></li>
                    <li><Link to="/ai" onClick={() => setMenuOpen(false)}>Ask Qarby</Link></li>
                    <li><Link to="/resources" onClick={() => setMenuOpen(false)}>Resources</Link></li>
                    <li><Link to="/favorites" onClick={() => setMenuOpen(false)}>Favorites</Link></li>
                    <li><Link to="/dealerships" onClick={() => setMenuOpen(false)}>Find Dealerships</Link></li>
                </ul>
            </nav>
        </header>
    );
};

export default Navbar;


// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import './Navbar.css';

// const Navbar = ({ account, onLogin, onLogout }) => {
//     const [menuOpen, setMenuOpen] = useState(false);

//     const getUsername = () => {
//         if (!account) return '';
//         return account.name ||
//                (account.idTokenClaims && account.idTokenClaims.name) ||
//                account.username ||
//                account.preferredUsername || '';
//     };

//     const toggleMenu = () => {
//         setMenuOpen(!menuOpen);
//     };

//     return (
//         <header className="header">
//             <div className="nav-top">
//                 <div className="navbar-left">
//                     <div className="brand-container">
//                         {/* Add an icon or SVG for the green gradient icon here */}
//                         <div className="brand-icon"></div>
//                         <Link className="navbar-logo" to="/">QARBY</Link>
//                     </div>
//                 </div>

//                 {/* Center section only visible on large screens */}
//                 <div className="navbar-center">
//                     <ul>
//                         <li><Link to="/about">About</Link></li>
//                         <li><Link to="/resources">Resources</Link></li>
//                         <li><Link to="/favorites">Fav</Link></li>
//                     </ul>
//                 </div>

//                 <div className="navbar-right">
//                     {/* A "Get Started" button styled as black, linking to Chatbot or another page */}
//                     <Link to="/ai" className="get-started-btn">Get Started</Link>
//                     <div className="auth-controls">
//                         {account ? (
//                             <>
//                                 <span className="welcome-message">Welcome, {getUsername()}!</span>
//                                 <button className="login-button" onClick={onLogout}>Logout</button>
//                             </>
//                         ) : (
//                             <button className="login-button" onClick={onLogin}>Login</button>
//                         )}
//                     </div>
//                     <div className="hamburger" onClick={toggleMenu}>
//                         <span className="bar"></span>
//                         <span className="bar"></span>
//                         <span className="bar"></span>
//                     </div>
//                 </div>
//             </div>

//             {/* Dropdown menu for small screens */}
//             <nav className={`navbar-links-container ${menuOpen ? 'open' : ''}`}>
//                 <ul className="navbar-links">
//                     <li><Link to="/" onClick={() => setMenuOpen(false)}>Home</Link></li>
//                     <li><Link to="/about" onClick={() => setMenuOpen(false)}>About</Link></li>
//                     <li><Link to="/ai" onClick={() => setMenuOpen(false)}>Ask Qarby</Link></li>
//                     <li><Link to="/resources" onClick={() => setMenuOpen(false)}>Resources</Link></li>
//                     <li><Link to="/favorites" onClick={() => setMenuOpen(false)}>Favorites</Link></li>
//                     <li><Link to="/dealerships" onClick={() => setMenuOpen(false)}>Find Dealerships</Link></li>
//                 </ul>
//             </nav>
//         </header>
//     );
// };

// export default Navbar;


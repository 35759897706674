import React, { useState } from 'react';
import './FindYourCar.css';
import RecommendationsDisplay from './RecommendationsDisplay';

const brandList = [
  'Acura', 'AFEELA', 'Alfa Romeo', 'Aston Martin', 'Audi', 'Bentley', 'BMW', 'Buick',
  'Cadillac', 'Chevrolet', 'Chrysler', 'Daewoo', 'Daihatsu', 'Dodge', 'Eagle', 'Ferrari',
  'FIAT', 'Fisker', 'Ford', 'Freightliner', 'Genesis', 'Geo', 'GMC', 'Honda', 'HUMMER',
  'Hyundai', 'INEOS', 'INFINITI', 'Isuzu', 'Jaguar', 'Jeep', 'Kia', 'Lamborghini',
  'Land Rover', 'Lexus', 'Lincoln', 'Lotus', 'Lucid', 'Maserati', 'Maybach', 'MAZDA',
  'McLaren', 'Mercedes-Benz', 'Mercury', 'MINI', 'Mitsubishi', 'Nissan', 'Oldsmobile',
  'Panoz', 'Plymouth', 'Polestar', 'Pontiac', 'Porsche', 'Ram', 'Rivian', 'Rolls-Royce',
  'Saab', 'Saturn', 'Scion', 'Scout', 'smart', 'SRT', 'Subaru', 'Suzuki', 'Tesla', 'Toyota',
  'VinFast', 'Volkswagen', 'Volvo'
];

const FindYourCar = ({ onBack }) => {
  const [step, setStep] = useState(1);

  // State to store user responses
  const [responses, setResponses] = useState({
    usage: [],
    brand: [],
    mustHaves: [],
    seats: '',
    bodyType: '',
    powerType: '',
    budgetMin: 0,
    budgetMax: 0,
  });

  const [chatResponse, setChatResponse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Handle brand selection (up to 3)
  const handleBrandChange = (value) => {
    setResponses((prev) => {
      const selectedBrands = [...prev.brand];
      if (selectedBrands.includes(value)) {
        // Remove if already selected
        return { ...prev, brand: selectedBrands.filter((b) => b !== value) };
      } else {
        // Add if we have room for up to 3
        if (selectedBrands.length < 3) {
          selectedBrands.push(value);
          return { ...prev, brand: selectedBrands };
        } else {
          alert('You can select up to 3 brands only.');
          return prev;
        }
      }
    });
  };

  // Handle generic input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // For multiple checkboxes (usage, mustHaves)
    if (type === 'checkbox' && (name === 'usage' || name === 'mustHaves')) {
      setResponses((prev) => {
        let newValues = [...prev[name]];
        if (checked) {
          newValues.push(value);
        } else {
          newValues = newValues.filter((item) => item !== value);
        }
        return { ...prev, [name]: newValues };
      });
    }
    // For the budget dropdown
    else if (name === 'budgetRange') {
      let min = 0;
      let max = 0;
      if (value === '< $10k') {
        min = 0;
        max = 10000;
      } else if (value === '$10k - $20k') {
        min = 10000;
        max = 20000;
      } else if (value === '$20k - $30k') {
        min = 20000;
        max = 30000;
      } else if (value === '$30k - $40k') {
        min = 30000;
        max = 40000;
      } else if (value === '$40k - $50k') {
        min = 40000;
        max = 50000;
      } else if (value === '>$50k') {
        min = 50000;
        max = 999999;
      }
      setResponses((prev) => ({
        ...prev,
        budgetMin: min,
        budgetMax: max,
      }));
    }
    // Otherwise just store the value (for seats, bodyType, powerType)
    else {
      setResponses((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // Validation: only Step 1 (usage) and Step 7 (budget) are required
  const validateStep = (currentStep) => {
    switch (currentStep) {
      case 1:
        return responses.usage.length > 0; // usage must have at least one item
      case 7:
        return responses.budgetMax > 0;    // must choose a valid budget range
      default:
        return true;                       // all other steps are optional
    }
  };

  // Move to next step
  const nextStep = () => {
    if (!validateStep(step)) {
      alert('Please fill out the required field(s) before proceeding.');
      return;
    }
    setStep((prev) => prev + 1);
  };

  // Move to previous step
  const prevStep = () => {
    setStep((prev) => prev - 1);
  };

  // Reset everything and go back to Step 1
  const handleStartOver = () => {
    setResponses({
      usage: [],
      brand: [],
      mustHaves: [],
      seats: '',
      bodyType: '',
      powerType: '',
      budgetMin: 0,
      budgetMax: 0,
    });
    setChatResponse([]);
    setStep(1);
  };

  const handleSubmit = async () => {
    // Double-check required fields on final submit
    if (!validateStep(step)) {
      alert('Please fill all required fields before submitting.');
      return;
    }

    setIsLoading(true);

    try {
      // Build the fields to send to your Azure Function
      const usageText = responses.usage.join(', ');
      const brandText = responses.brand.join(', ') || 'No particular preference';
      const mustHavesText = responses.mustHaves.length
        ? responses.mustHaves.join(', ')
        : 'No particular must-haves';

      const functionResponse = await fetch(
        'https://carprojfunctionappnew.azurewebsites.net/api/generatecarrecommendations',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            usageText,
            brandText,
            mustHavesText,
            seats: responses.seats || 'No specific requirement',
            bodyType: responses.bodyType || 'No specific requirement',
            powerType: responses.powerType || 'No specific requirement',
            budgetMin: responses.budgetMin,
            budgetMax: responses.budgetMax,
          }),
        }
      );

      if (!functionResponse.ok) {
        throw new Error(`Azure Function error: ${functionResponse.status}`);
      }

      // If your function truly returns JSON, you can do:
      // const recommendations = await functionResponse.json();

      // Otherwise, if it returns text that *should* be valid JSON:
      const rawContent = await functionResponse.text();
      const sanitizedContent = rawContent.replace(/```(?:json)?/g, '').trim();
      const recommendations = JSON.parse(sanitizedContent);

      // Fetch an image for each recommendation
      const recommendationsWithImages = await Promise.all(
        recommendations.map(async (car) => {
          try {
            const imageResponse = await fetch(
              `https://carprojfunctionappnew.azurewebsites.net/api/imageSearch?q=${encodeURIComponent(
                car.name
              )}`
            );
            const imageData = await imageResponse.json();
            return { ...car, imageUrl: imageData.imageUrl };
          } catch (err) {
            console.error('Error fetching image', err);
            return { ...car, imageUrl: '' };
          }
        })
      );

      // (Optional) scoring logic
      const scoredRecommendations = recommendationsWithImages.map((car) => {
        let score = 0;

        // If user's brand preference appears in car name, bump the score
        if (responses.brand.length) {
          for (const preferredBrand of responses.brand) {
            if (car.name.toLowerCase().includes(preferredBrand.toLowerCase())) {
              score += 20;
            }
          }
        }

        // If user must have "All-wheel drive" and the description mentions AWD
        if (
          responses.mustHaves.includes('All-wheel drive') &&
          (
            car.description.toLowerCase().includes('all-wheel') ||
            car.lookFor.toLowerCase().includes('all-wheel')
          )
        ) {
          score += 10;
        }

        return { ...car, score };
      });

      // Sort descending by score
      scoredRecommendations.sort((a, b) => b.score - a.score);

      // Keep top 10
      const finalTen = scoredRecommendations.slice(0, 10);

      setChatResponse(finalTen);
    } catch (error) {
      console.error('Error fetching recommendations:', error);
      alert('There was an error fetching recommendations. Please try again later.');
      setChatResponse([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Renders only what the user has *passed* so far
  const renderCurrentSelections = () => {
    // If we are on Step 1, the user hasn't *passed* any steps yet, so show nothing
    if (step <= 1) return null;

    // For each step, only show if the user is past that step:
    const usage = step > 1 ? responses.usage.join(', ') || 'None' : '';
    const brand = step > 2 ? responses.brand.join(', ') || 'None' : '';
    const mustHaves = step > 3 ? responses.mustHaves.join(', ') || 'None' : '';
    const seats = step > 4 ? (responses.seats || 'None') : '';
    const bodyType = step > 5 ? (responses.bodyType || 'None') : '';
    const powerType = step > 6 ? (responses.powerType || 'None') : '';
    // For step 7, show final budget
    const budgetMin = step > 7 ? responses.budgetMin : 0;
    const budgetMax = step > 7 ? responses.budgetMax : 0;

    return (
      <div className="current-selections" style={{ marginTop: '20px' }}>
        <h4>Your current selections so far:</h4>
        <ul>
          {step > 1 && <li><strong>Usage:</strong> {usage}</li>}
          {step > 2 && <li><strong>Brand:</strong> {brand}</li>}
          {step > 3 && <li><strong>Must-Haves:</strong> {mustHaves}</li>}
          {step > 4 && <li><strong>Seats:</strong> {seats}</li>}
          {step > 5 && <li><strong>Body Type:</strong> {bodyType}</li>}
          {step > 6 && <li><strong>Power Type:</strong> {powerType}</li>}
          {step > 7 && (
            <>
              <li><strong>Budget Min:</strong> {budgetMin}</li>
              <li><strong>Budget Max:</strong> {budgetMax}</li>
            </>
          )}
        </ul>
      </div>
    );
  };

  return (
    <div className="findyourcar-container">
      {/* A "Back" button to close or go to a previous page/routing */}
      <button className="back-button" onClick={onBack}>← Back</button>

      <div className="progress-bar">
        <div
          className="progress"
          style={{ width: `${(step / 7) * 100}%` }}
        />
      </div>

      <div className="step-content">
        {/* STEP 1: Usage (REQUIRED) */}
        {step === 1 && (
          <>
            <h2>Step 1 of 7</h2>
            <h3>What will you use this car for?</h3>
            <p>Select all that apply (required):</p>
            <div className="checkbox-group">
              <label>
                <input
                  type="checkbox"
                  name="usage"
                  value="Household errands"
                  checked={responses.usage.includes('Household errands')}
                  onChange={handleInputChange}
                />
                <span>Household errands</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  name="usage"
                  value="City commutes"
                  checked={responses.usage.includes('City commutes')}
                  onChange={handleInputChange}
                />
                <span>City commutes</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  name="usage"
                  value="Long-distance trips"
                  checked={responses.usage.includes('Long-distance trips')}
                  onChange={handleInputChange}
                />
                <span>Long-distance trips</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  name="usage"
                  value="Fun car/ Guilty Pleasure"
                  checked={responses.usage.includes('Fun car/ Guilty Pleasure')}
                  onChange={handleInputChange}
                />
                <span>Fun car/ Guilty Pleasure</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  name="usage"
                  value="Ridesharing"
                  checked={responses.usage.includes('Ridesharing')}
                  onChange={handleInputChange}
                />
                <span>Ridesharing</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  name="usage"
                  value="Camping/fishing"
                  checked={responses.usage.includes('Camping/fishing')}
                  onChange={handleInputChange}
                />
                <span>Camping/fishing</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  name="usage"
                  value="Large cargo"
                  checked={responses.usage.includes('Large cargo')}
                  onChange={handleInputChange}
                />
                <span>Large cargo</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  name="usage"
                  value="Driving in snow or off-road"
                  checked={responses.usage.includes('Driving in snow or off-road')}
                  onChange={handleInputChange}
                />
                <span>Driving in snow or off-road</span>
              </label>
            </div>

            <div className="pagination">
              {/* No "Back" button on Step 1 since it's the first step */}
              <button onClick={nextStep}>Next</button>
            </div>
          </>
        )}

        {/* STEP 2: Brand (OPTIONAL) */}
        {step === 2 && (
          <>
            <h2>Step 2 of 7</h2>
            <h3>Which car brand(s) do you like? (Optional - up to 3)</h3>
            <div className="multiselect-brands">
              {brandList.map((brandOption) => (
                <label key={brandOption}>
                  <input
                    type="checkbox"
                    value={brandOption}
                    checked={responses.brand.includes(brandOption)}
                    onChange={() => handleBrandChange(brandOption)}
                  />
                  <span>{brandOption}</span>
                </label>
              ))}
            </div>

            {renderCurrentSelections()}
            <div className="pagination">
              <button onClick={prevStep}>Back</button>
              <button onClick={nextStep}>Next</button>
            </div>
          </>
        )}

        {/* STEP 3: Must-Have Features (OPTIONAL) */}
        {step === 3 && (
          <>
            <h2>Step 3 of 7</h2>
            <h3>What are your must-have features? (Optional)</h3>
            <div className="checkbox-group">
              <label>
                <input
                  type="checkbox"
                  name="mustHaves"
                  value="Navigation"
                  checked={responses.mustHaves.includes('Navigation')}
                  onChange={handleInputChange}
                />
                <span>Navigation</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  name="mustHaves"
                  value="Wi-Fi"
                  checked={responses.mustHaves.includes('Wi-Fi')}
                  onChange={handleInputChange}
                />
                <span>Wi-Fi</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  name="mustHaves"
                  value="Heated seats"
                  checked={responses.mustHaves.includes('Heated seats')}
                  onChange={handleInputChange}
                />
                <span>Heated seats</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  name="mustHaves"
                  value="Sunroof"
                  checked={responses.mustHaves.includes('Sunroof')}
                  onChange={handleInputChange}
                />
                <span>Sunroof</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  name="mustHaves"
                  value="Leather seats"
                  checked={responses.mustHaves.includes('Leather seats')}
                  onChange={handleInputChange}
                />
                <span>Leather seats</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  name="mustHaves"
                  value="All-wheel drive"
                  checked={responses.mustHaves.includes('All-wheel drive')}
                  onChange={handleInputChange}
                />
                <span>All-wheel drive</span>
              </label>
            </div>

            {renderCurrentSelections()}
            <div className="pagination">
              <button onClick={prevStep}>Back</button>
              <button onClick={nextStep}>Next</button>
            </div>
          </>
        )}

        {/* STEP 4: Seats (OPTIONAL) */}
        {step === 4 && (
          <>
            <h2>Step 4 of 7</h2>
            <h3>How many total seats do you need? (Optional)</h3>
            <select
              name="seats"
              value={responses.seats}
              onChange={handleInputChange}
            >
              <option value="">No preference</option>
              <option value="2">2</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7+">7+</option>
            </select>

            {renderCurrentSelections()}
            <div className="pagination">
              <button onClick={prevStep}>Back</button>
              <button onClick={nextStep}>Next</button>
            </div>
          </>
        )}

        {/* STEP 5: Body Type (OPTIONAL) */}
        {step === 5 && (
          <>
            <h2>Step 5 of 7</h2>
            <h3>Preferred type of vehicle? (Optional)</h3>
            <select
              name="bodyType"
              value={responses.bodyType}
              onChange={handleInputChange}
            >
              <option value="">No preference</option>
              <option value="Sedan">Sedan</option>
              <option value="Coupe">Coupe</option>
              <option value="SUV">SUV</option>
              <option value="Truck">Truck</option>
              <option value="Minivan">Minivan</option>
              <option value="Convertible">Convertible</option>
              <option value="Hatchback">Hatchback</option>
            </select>

            {renderCurrentSelections()}
            <div className="pagination">
              <button onClick={prevStep}>Back</button>
              <button onClick={nextStep}>Next</button>
            </div>
          </>
        )}

        {/* STEP 6: Power Type (OPTIONAL) */}
        {step === 6 && (
          <>
            <h2>Step 6 of 7</h2>
            <h3>What type of power do you want? (Optional)</h3>
            <select
              name="powerType"
              value={responses.powerType}
              onChange={handleInputChange}
            >
              <option value="">No preference</option>
              <option value="Gas">Gas</option>
              <option value="Hybrid">Hybrid</option>
              <option value="Electric">Electric</option>
              <option value="Diesel">Diesel</option>
            </select>

            {renderCurrentSelections()}
            <div className="pagination">
              <button onClick={prevStep}>Back</button>
              <button onClick={nextStep}>Next</button>
            </div>
          </>
        )}

        {/* STEP 7: Budget (REQUIRED) */}
        {step === 7 && (
          <>
            <h2>Step 7 of 7</h2>
            <h3>What’s your budget range? (Required)</h3>
            <select name="budgetRange" onChange={handleInputChange}>
              <option value="">Select</option>
              <option value="< $10k">&lt; $10k</option>
              <option value="$10k - $20k">$10k - $20k</option>
              <option value="$20k - $30k">$20k - $30k</option>
              <option value="$30k - $40k">$30k - $40k</option>
              <option value="$40k - $50k">$40k - $50k</option>
              <option value=">$50k">&gt; $50k</option>
            </select>

            {renderCurrentSelections()}
            <div className="pagination">
              <button onClick={prevStep}>Back</button>
              <button
                onClick={handleSubmit}
                disabled={isLoading || responses.budgetMax === 0}
              >
                {isLoading ? 'Loading...' : 'Submit'}
              </button>
            </div>
          </>
        )}
      </div>

      {/* After submitting, display GPT Recommendations (if any) */}
      {chatResponse.length > 0 && (
        <RecommendationsDisplay
          recommendations={chatResponse}
          responses={responses}
          onStartOver={handleStartOver}
        />
      )}
    </div>
  );
};

export default FindYourCar;
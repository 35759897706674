// src/pages/AskQarbyPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AskQarby from '../components/AskQarby';

const AskQarbyPage = () => {
    const navigate = useNavigate();

    return (
        <div className="chatbot-layout">
            <div className="sidebar">
                <button className="ios-back-button" onClick={() => navigate('/ai')}>
                    ← Back
                </button>
            </div>
            <div className="main-content">
                <AskQarby />
            </div>
        </div>
    );
};

export default AskQarbyPage;

// src/components/Footer.js

import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Make sure Footer.css contains the footer styles

const Footer = () => {
    return (
        <footer className="home-footer">
            <div className="footer-columns">
                <div className="footer-column">
                    <h3>Company</h3>
                    <ul>
                        <li><Link to="/about">About Us</Link></li>
                        <li><Link to="/deal">Get a deal with Qarby</Link></li>
                        <li><Link to="/careers">Careers</Link></li>
                    </ul>
                </div>
                <div className="footer-column">
                    <h3>Legal</h3>
                    <ul>
                        <li><Link to="/compliance">Compliance</Link></li>
                        <li><Link to="/privacy">Privacy Policy</Link></li>
                        <li><Link to="/dealer-awareness">Dealer Awareness Policy</Link></li>
                        <li><Link to="/terms">Terms & Conditions</Link></li>
                    </ul>
                </div>
                <div className="footer-column">
                    <h3>Resources</h3>
                    <ul>
                        <li><Link to="/integrate-ai">Integrate Qarby AI</Link></li>
                        <li><Link to="/api">Qarby Api/SDK</Link></li>
                        {/* <-- Add a Dealership Portal link here --> */}
                        <li><Link to="/dealership-portal">Dealership Portal</Link></li>
                    </ul>
                </div>
                <div className="footer-column">
                    <h3>Contact Us</h3>
                    <ul>
                        <li><Link to="/sales">Contact Sales</Link></li>
                        <li><a href="mailto:info@qarby.com">Info@Qarby.com</a></li>
                    </ul>
                </div>
            </div>
            <div className="footer-bottom">
                <p>© 2024 Qarby, All rights reserved</p>
                <div className="social-icons">
                    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
                    <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;


// // src/components/Footer.js
// import React from 'react';
// import { Link } from 'react-router-dom';
// import './Footer.css'; // Make sure Footer.css contains the footer styles

// const Footer = () => {
//     return (
//         <footer className="home-footer">
//             <div className="footer-columns">
//                 <div className="footer-column">
//                     <h3>Company</h3>
//                     <ul>
//                         <li><Link to="/about">About Us</Link></li>
//                         <li><Link to="/deal">Get a deal with Qarby</Link></li>
//                         <li><Link to="/careers">Careers</Link></li>
//                     </ul>
//                 </div>
//                 <div className="footer-column">
//                     <h3>Legal</h3>
//                     <ul>
//                         <li><Link to="/compliance">Compliance</Link></li>
//                         <li><Link to="/privacy">Privacy Policy</Link></li>
//                         <li><Link to="/dealer-awareness">Dealer Awareness Policy</Link></li>
//                         <li><Link to="/terms">Terms & Conditions</Link></li>
//                     </ul>
//                 </div>
//                 <div className="footer-column">
//                     <h3>Resources</h3>
//                     <ul>
//                         <li><Link to="/integrate-ai">Integrate Qarby AI</Link></li>
//                         <li><Link to="/api">Qarby Api/SDK</Link></li>
//                     </ul>
//                 </div>
//                 <div className="footer-column">
//                     <h3>Contact Us</h3>
//                     <ul>
//                         <li><Link to="/sales">Contact Sales</Link></li>
//                         <li><a href="mailto:info@qarby.com">Info@Qarby.com</a></li>
//                     </ul>
//                 </div>
//             </div>
//             <div className="footer-bottom">
//                 <p>© 2024 Qarby, All rights reserved</p>
//                 <div className="social-icons">
//                     <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
//                     <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">LinkedIn</a>
//                 </div>
//             </div>
//         </footer>
//     );
// };

// export default Footer;

import React, { useState, useEffect } from 'react';
import './DealershipFinder.css';

const DealershipFinder = () => {
  const [dealerships, setDealerships] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    getUserLocation();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getUserLocation = () => {
    if (!navigator.geolocation) {
      setError('Geolocation is not supported by your browser');
      return;
    }

    setLoading(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        fetchNearbyDealerships(position.coords.latitude, position.coords.longitude);
      },
      (error) => {
        console.error('Geolocation error:', error);
        setError('Unable to retrieve your location. Please make sure location services are enabled.');
        setLoading(false);
      },
      { timeout: 10000 } // 10 second timeout
    );
  };

  const fetchNearbyDealerships = async (latitude, longitude) => {
    try {
      const response = await fetch(`https://carprojfunctionappnew.azurewebsites.net/api/getDealerships?lat=${latitude}&lng=${longitude}`);
      console.log('Response status:', response.status);
      
      const textResponse = await response.text();
      console.log('Raw response:', textResponse);
      
      let data;
      try {
        data = JSON.parse(textResponse);
      } catch (parseError) {
        console.error('Failed to parse JSON:', parseError);
        throw new Error('Invalid response format from server');
      }
      
      if (!response.ok) {
        throw new Error(data.message || 'Failed to fetch dealerships');
      }

      setDealerships(data.dealerships || []);
    } catch (err) {
      console.error('Error details:', err);
      setError('Unable to fetch dealerships. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="dealership-container">
      <div className="dealership-header">
        <h2>Nearby Car Dealerships</h2>
        {!error && !loading && dealerships.length > 0 && (
          <p className="dealership-count">Found {dealerships.length} dealerships near you</p>
        )}
      </div>
      
      {error && (
        <div className="error-message">
          <p>{error}</p>
          <button 
            onClick={getUserLocation} 
            className="retry-button"
          >
            Try Again
          </button>
        </div>
      )}

      {loading ? (
        <div className="loading-spinner">Loading dealerships...</div>
      ) : (
        <div className="dealership-grid">
          {dealerships.map((dealership) => (
            <div key={dealership.id} className="dealership-card">
              <h3 className="dealership-name">{dealership.name}</h3>
              <p className="dealership-address">{dealership.address}</p>
              {dealership.rating && (
                <div className="dealership-rating">
                  <span className="rating-star">★</span>
                  <span>{dealership.rating.toFixed(1)} ({dealership.totalRatings} reviews)</span>
                </div>
              )}
              {dealership.openNow !== undefined && (
                <div className={`dealership-status ${dealership.openNow ? 'status-open' : 'status-closed'}`}>
                  {dealership.openNow ? 'Open Now' : 'Closed'}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DealershipFinder;

// import React, { useState, useEffect } from 'react';
// import './DealershipFinder.css';

// const DealershipFinder = () => {
//   const [dealerships, setDealerships] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     getUserLocation();
//   }, []); // eslint-disable-line react-hooks/exhaustive-deps

//   const getUserLocation = () => {
//     if (!navigator.geolocation) {
//       setError('Geolocation is not supported by your browser');
//       return;
//     }

//     setLoading(true);
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         fetchNearbyDealerships(position.coords.latitude, position.coords.longitude);
//       },
//       (error) => {
//         setError('Unable to retrieve your location');
//         setLoading(false);
//       }
//     );
//   };

//   const fetchNearbyDealerships = async (latitude, longitude) => {
//     try {
//       const response = await fetch(`https://carprojfunctionappnew.azurewebsites.net/api/getDealerships?lat=${latitude}&lng=${longitude}`);
//       console.log('Response status:', response.status);
      
//       const textResponse = await response.text();
//       console.log('Raw response:', textResponse);
      
//       let data;
//       try {
//         data = JSON.parse(textResponse);
//       } catch (parseError) {
//         console.error('Failed to parse JSON:', parseError);
//         throw new Error('Invalid response format from server');
//       }
      
//       if (!response.ok) {
//         throw new Error(data.message || 'Failed to fetch dealerships');
//       }

//       setDealerships(data.dealerships);
//     } catch (err) {
//       console.error('Error details:', err);
//       setError(err.message);
//     } finally {
//       setLoading(false);
//     }
// };

// return (
//     <div className="dealership-container">
//       <div className="dealership-header">
//         <h2>Nearby Car Dealerships</h2>
//       </div>
      
//       {error && (
//         <div className="error-message">
//           {error}
//         </div>
//       )}

//       {loading ? (
//         <div className="loading-spinner">Loading dealerships...</div>
//       ) : (
//         <div className="dealership-grid">
//           {dealerships.map((dealership) => (
//             <div key={dealership.id} className="dealership-card">
//               <h3 className="dealership-name">{dealership.name}</h3>
//               <p className="dealership-address">{dealership.address}</p>
//               {dealership.rating && (
//                 <div className="dealership-rating">
//                   <span className="rating-star">★</span>
//                   <span>{dealership.rating} ({dealership.totalRatings} reviews)</span>
//                 </div>
//               )}
//               {dealership.openNow !== undefined && (
//                 <div className={`dealership-status ${dealership.openNow ? 'status-open' : 'status-closed'}`}>
//                   {dealership.openNow ? 'Open Now' : 'Closed'}
//                 </div>
//               )}
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default DealershipFinder;
// File: src/pages/DealerPortal.jsx
import React, { useState, useEffect } from "react";
import "./DealerPortal.css";

function DealerPortal() {
  const [typedId, setTypedId] = useState("");
  const [dealershipId, setDealershipId] = useState("");
  const [dealershipName, setDealershipName] = useState("");

  // Vehicle form fields
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [year, setYear] = useState("");
  const [trim, setTrim] = useState("");
  const [color, setColor] = useState("");
  const [transmission, setTransmission] = useState("");
  const [mileage, setMileage] = useState("");
  const [vin, setVin] = useState("");
  const [vehicleHistoryLink, setVehicleHistoryLink] = useState("");
  const [price, setPrice] = useState("");

  const [inventory, setInventory] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  // NEW: track which item ID we're editing (null if adding new)
  const [editId, setEditId] = useState(null);

  // Step 1: Verify dealership ID
  const handleVerifyId = async () => {
    if (!typedId) {
      alert("Please enter a Dealership ID.");
      return;
    }
    try {
      const response = await fetch(
        `https://carprojfunctionappnew.azurewebsites.net/api/dealershipcars?checkId=${typedId}`
      );
      if (!response.ok) {
        const { error } = await response.json();
        throw new Error(error || "ID not found.");
      }
      const data = await response.json();
      if (data.found) {
        setDealershipId(data.id);
        setDealershipName(data.name);
        alert(`Welcome, ${data.name}!`);
      }
    } catch (err) {
      alert(err.message);
    }
  };

  // Step 2: Fetch inventory
  const fetchInventory = async (theDealershipId) => {
    try {
      const response = await fetch(
        `https://carprojfunctionappnew.azurewebsites.net/api/dealershipcars?dealershipId=${theDealershipId}`
      );
      if (!response.ok) {
        const { error } = await response.json();
        throw new Error(error || "Failed to fetch inventory");
      }
      const data = await response.json();
      setInventory(data);
      setSelectedIds([]);
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    if (dealershipId) {
      fetchInventory(dealershipId);
    }
  }, [dealershipId]);

  // 3) Add or Update vehicle (depending on editId)
  const handleAddVehicle = async (e) => {
    e.preventDefault();
    if (!dealershipId || !make || !model || !year) {
      alert("Please verify ID first, then provide make, model, and year.");
      return;
    }

    // Build request body
    const requestBody = {
      dealershipId,
      make,
      model,
      year,
      trim,
      color,
      transmission,
      mileage,
      vin,
      vehicleHistoryLink,
      price
    };

    // If we're editing an existing item, include that ID so .upsert() updates it
    if (editId) {
      requestBody.id = editId;
    }

    try {
      const response = await fetch(
        "https://carprojfunctionappnew.azurewebsites.net/api/dealershipcars",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody)
        }
      );
      if (!response.ok) {
        const { error } = await response.json();
        throw new Error(error || "Failed to add/update vehicle");
      }

      if (editId) {
        alert("Vehicle updated successfully!");
      } else {
        alert("Vehicle added successfully!");
      }

      // Reload inventory
      fetchInventory(dealershipId);

      // Clear form and edit state
      setMake("");
      setModel("");
      setYear("");
      setTrim("");
      setColor("");
      setTransmission("");
      setMileage("");
      setVin("");
      setVehicleHistoryLink("");
      setPrice("");
      setEditId(null); // reset
    } catch (err) {
      alert(err.message);
    }
  };

  // 4) Delete selected
  const handleDeleteSelected = async () => {
    if (!dealershipId) {
      alert("Please verify ID first.");
      return;
    }
    if (selectedIds.length === 0) {
      alert("No vehicles selected for deletion.");
      return;
    }
    const confirmDelete = window.confirm(
      `Delete ${selectedIds.length} vehicle(s) from inventory?`
    );
    if (!confirmDelete) return;

    try {
      const response = await fetch(
        "https://carprojfunctionappnew.azurewebsites.net/api/dealershipcars",
        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            dealershipId,
            selectedIds
          })
        }
      );
      if (!response.ok) {
        const { error } = await response.json();
        throw new Error(error || "Failed to delete vehicles");
      }
      alert("Selected vehicles deleted successfully!");
      fetchInventory(dealershipId);
    } catch (err) {
      alert(err.message);
    }
  };

  // 5) Enter "Edit" mode for one item
  const handleEdit = (car) => {
    // Populate form fields
    setEditId(car.id);
    setMake(car.make || "");
    setModel(car.model || "");
    setYear(car.year || "");
    setTrim(car.trim || "");
    setColor(car.color || "");
    setTransmission(car.transmission || "");
    setMileage(car.mileage || "");
    setVin(car.vin || "");
    setVehicleHistoryLink(car.vehicleHistoryLink || "");
    setPrice(car.price || "");
  };

  // 6) Checkboxes for deletion
  const handleCheckboxChange = (carId, checked) => {
    if (checked) {
      setSelectedIds((prev) => [...prev, carId]);
    } else {
      setSelectedIds((prev) => prev.filter((id) => id !== carId));
    }
  };

  return (
    <div className="portal-container">
      <h2>Dealership Portal</h2>

      {/* Step 1: Enter/Verify ID if not already verified */}
      {!dealershipId ? (
        <div className="verify-id-block">
          <label>
            Enter Dealership ID:{" "}
            <input
              type="text"
              value={typedId}
              onChange={(e) => setTypedId(e.target.value)}
            />
          </label>
          <button onClick={handleVerifyId}>Verify ID</button>
        </div>
      ) : (
        // If verified, show name
        <div className="verified-block">
          <h3>Welcome, {dealershipName} (ID: {dealershipId})</h3>
        </div>
      )}

      {/* Show inventory ops only if ID is verified */}
      {dealershipId && (
        <>
          <form onSubmit={handleAddVehicle} className="add-vehicle-form">
            <h3>{editId ? "Update Vehicle" : "Add a Vehicle"}</h3>
            <label>
              Make:{" "}
              <input
                type="text"
                value={make}
                onChange={(e) => setMake(e.target.value)}
              />
            </label>
            <label>
              Model:{" "}
              <input
                type="text"
                value={model}
                onChange={(e) => setModel(e.target.value)}
              />
            </label>
            <label>
              Year:{" "}
              <input
                type="number"
                value={year}
                onChange={(e) => setYear(e.target.value)}
              />
            </label>
            <label>
              Trim:{" "}
              <input
                type="text"
                value={trim}
                onChange={(e) => setTrim(e.target.value)}
              />
            </label>
            <label>
              Color:{" "}
              <input
                type="text"
                value={color}
                onChange={(e) => setColor(e.target.value)}
              />
            </label>
            <label>
              Transmission:{" "}
              <input
                type="text"
                value={transmission}
                onChange={(e) => setTransmission(e.target.value)}
              />
            </label>
            <label>
              Mileage:{" "}
              <input
                type="number"
                value={mileage}
                onChange={(e) => setMileage(e.target.value)}
              />
            </label>
            <label>
              VIN:{" "}
              <input
                type="text"
                value={vin}
                onChange={(e) => setVin(e.target.value)}
              />
            </label>
            <label>
              Vehicle History Link:{" "}
              <input
                type="text"
                value={vehicleHistoryLink}
                onChange={(e) => setVehicleHistoryLink(e.target.value)}
              />
            </label>

            {/* Price input */}
            <label>
              Price:{" "}
              <input
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </label>

            <button type="submit" className="btn-add">
              {editId ? "Update Vehicle" : "Add Vehicle"}
            </button>
          </form>

          <h3>Current Inventory</h3>
          {inventory.length > 0 ? (
            <>
              <button onClick={handleDeleteSelected} className="btn-delete">
                Delete Selected
              </button>
              <table className="inventory-table">
                <thead>
                  <tr>
                    <th>Select</th>
                    <th>Make</th>
                    <th>Model</th>
                    <th>Year</th>
                    <th>Trim</th>
                    <th>Color</th>
                    <th>Transmission</th>
                    <th>Mileage</th>
                    <th>VIN</th>
                    <th>Price</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {inventory.map((car) => {
                    const isChecked = selectedIds.includes(car.id);
                    return (
                      <tr key={car.id}>
                        <td>
                          <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={(e) =>
                              handleCheckboxChange(car.id, e.target.checked)
                            }
                          />
                        </td>
                        <td>{car.make}</td>
                        <td>{car.model}</td>
                        <td>{car.year}</td>
                        <td>{car.trim}</td>
                        <td>{car.color}</td>
                        <td>{car.transmission}</td>
                        <td>{car.mileage}</td>
                        <td>{car.vin}</td>
                        <td>{car.price || ""}</td>
                        <td>
                          <button onClick={() => handleEdit(car)}>Edit</button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          ) : (
            <p>No vehicles in inventory yet.</p>
          )}
        </>
      )}
    </div>
  );
}

export default DealerPortal;


// // File: src/pages/DealerPortal.jsx

// import React, { useState, useEffect } from "react";
// import "./DealerPortal.css";

// /**
//  * DealerPortal (No MSAL required)
//  * 1. User enters a "Dealership ID" to verify in "idmap" (database: "dealerships", container: "idmap").
//  * 2. If found, we store that ID + name, and load inventory from container: "inventory".
//  * 3. User can add or delete vehicles under that dealershipId.
//  * 4. Now includes 'price' field in form and table.
//  */
// function DealerPortal() {
//   const [typedId, setTypedId] = useState("");
//   const [dealershipId, setDealershipId] = useState("");
//   const [dealershipName, setDealershipName] = useState("");

//   // Vehicle form fields
//   const [make, setMake] = useState("");
//   const [model, setModel] = useState("");
//   const [year, setYear] = useState("");
//   const [trim, setTrim] = useState("");
//   const [color, setColor] = useState("");
//   const [transmission, setTransmission] = useState("");
//   const [mileage, setMileage] = useState("");
//   const [vin, setVin] = useState("");
//   const [vehicleHistoryLink, setVehicleHistoryLink] = useState("");

//   // NEW: Price field
//   const [price, setPrice] = useState("");

//   const [inventory, setInventory] = useState([]);
//   const [selectedIds, setSelectedIds] = useState([]);

//   // Step 1: Verify dealership ID in "idmap"
//   const handleVerifyId = async () => {
//     if (!typedId) {
//       alert("Please enter a Dealership ID.");
//       return;
//     }
//     try {
//       // Check if typedId exists
//       const response = await fetch(
//         `https://carprojfunctionappnew.azurewebsites.net/api/dealershipcars?checkId=${typedId}`
//       );
//       if (!response.ok) {
//         const { error } = await response.json();
//         throw new Error(error || "ID not found.");
//       }
//       const data = await response.json();
//       if (data.found) {
//         setDealershipId(data.id);      // e.g. "12345"
//         setDealershipName(data.name);  // e.g. "Smith Auto Group"
//         alert(`Welcome, ${data.name}!`);
//       }
//     } catch (err) {
//       alert(err.message);
//     }
//   };

//   // Step 2: Once we have a verified dealershipId, fetch inventory from container "inventory"
//   const fetchInventory = async (theDealershipId) => {
//     try {
//       const response = await fetch(
//         `https://carprojfunctionappnew.azurewebsites.net/api/dealershipcars?dealershipId=${theDealershipId}`
//       );
//       if (!response.ok) {
//         const { error } = await response.json();
//         throw new Error(error || "Failed to fetch inventory");
//       }
//       const data = await response.json();
//       setInventory(data);
//       setSelectedIds([]);
//     } catch (err) {
//       alert(err.message);
//     }
//   };

//   useEffect(() => {
//     // If we have a verified dealershipId, load the inventory
//     if (dealershipId) {
//       fetchInventory(dealershipId);
//     }
//   }, [dealershipId]);

//   // Add a vehicle
//   const handleAddVehicle = async (e) => {
//     e.preventDefault();
//     if (!dealershipId || !make || !model || !year) {
//       alert("Please verify ID first, then provide make, model, and year.");
//       return;
//     }
//     try {
//       const response = await fetch(
//         "https://carprojfunctionappnew.azurewebsites.net/api/dealershipcars",
//         {
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify({
//             dealershipId,
//             make,
//             model,
//             year,
//             trim,
//             color,
//             transmission,
//             mileage,
//             vin,
//             vehicleHistoryLink,
//             price   // Include price in request body
//           })
//         }
//       );
//       if (!response.ok) {
//         const { error } = await response.json();
//         throw new Error(error || "Failed to add vehicle");
//       }

//       alert("Vehicle added successfully!");
//       fetchInventory(dealershipId);

//       // Clear form
//       setMake("");
//       setModel("");
//       setYear("");
//       setTrim("");
//       setColor("");
//       setTransmission("");
//       setMileage("");
//       setVin("");
//       setVehicleHistoryLink("");
//       setPrice(""); // Clear price too
//     } catch (err) {
//       alert(err.message);
//     }
//   };

//   // Delete selected
//   const handleDeleteSelected = async () => {
//     if (!dealershipId) {
//       alert("Please verify ID first.");
//       return;
//     }
//     if (selectedIds.length === 0) {
//       alert("No vehicles selected for deletion.");
//       return;
//     }
//     const confirmDelete = window.confirm(
//       `Delete ${selectedIds.length} vehicle(s) from inventory?`
//     );
//     if (!confirmDelete) return;

//     try {
//       const response = await fetch(
//         "https://carprojfunctionappnew.azurewebsites.net/api/dealershipcars",
//         {
//           method: "DELETE",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify({
//             dealershipId,
//             selectedIds
//           })
//         }
//       );
//       if (!response.ok) {
//         const { error } = await response.json();
//         throw new Error(error || "Failed to delete vehicles");
//       }
//       alert("Selected vehicles deleted successfully!");
//       fetchInventory(dealershipId);
//     } catch (err) {
//       alert(err.message);
//     }
//   };

//   // Checkbox toggling
//   const handleCheckboxChange = (carId, checked) => {
//     if (checked) {
//       setSelectedIds((prev) => [...prev, carId]);
//     } else {
//       setSelectedIds((prev) => prev.filter((id) => id !== carId));
//     }
//   };

//   return (
//     <div className="portal-container">
//       <h2>Dealership Portal</h2>

//       {/* Step 1: Enter/Verify ID if not already verified */}
//       {!dealershipId ? (
//         <div className="verify-id-block">
//           <label>
//             Enter Dealership ID:{" "}
//             <input
//               type="text"
//               value={typedId}
//               onChange={(e) => setTypedId(e.target.value)}
//             />
//           </label>
//           <button onClick={handleVerifyId}>Verify ID</button>
//         </div>
//       ) : (
//         // If verified, show name
//         <div className="verified-block">
//           <h3>Welcome, {dealershipName} (ID: {dealershipId})</h3>
//         </div>
//       )}

//       {/* Show inventory ops only if ID is verified */}
//       {dealershipId && (
//         <>
//           <form onSubmit={handleAddVehicle} className="add-vehicle-form">
//             <h3>Add a Vehicle</h3>
//             <label>
//               Make:{" "}
//               <input
//                 type="text"
//                 value={make}
//                 onChange={(e) => setMake(e.target.value)}
//               />
//             </label>
//             <label>
//               Model:{" "}
//               <input
//                 type="text"
//                 value={model}
//                 onChange={(e) => setModel(e.target.value)}
//               />
//             </label>
//             <label>
//               Year:{" "}
//               <input
//                 type="number"
//                 value={year}
//                 onChange={(e) => setYear(e.target.value)}
//               />
//             </label>
//             <label>
//               Trim:{" "}
//               <input
//                 type="text"
//                 value={trim}
//                 onChange={(e) => setTrim(e.target.value)}
//               />
//             </label>
//             <label>
//               Color:{" "}
//               <input
//                 type="text"
//                 value={color}
//                 onChange={(e) => setColor(e.target.value)}
//               />
//             </label>
//             <label>
//               Transmission:{" "}
//               <input
//                 type="text"
//                 value={transmission}
//                 onChange={(e) => setTransmission(e.target.value)}
//               />
//             </label>
//             <label>
//               Mileage:{" "}
//               <input
//                 type="number"
//                 value={mileage}
//                 onChange={(e) => setMileage(e.target.value)}
//               />
//             </label>
//             <label>
//               VIN:{" "}
//               <input
//                 type="text"
//                 value={vin}
//                 onChange={(e) => setVin(e.target.value)}
//               />
//             </label>
//             <label>
//               Vehicle History Link:{" "}
//               <input
//                 type="text"
//                 value={vehicleHistoryLink}
//                 onChange={(e) => setVehicleHistoryLink(e.target.value)}
//               />
//             </label>

//             {/* NEW: Price input */}
//             <label>
//               Price:{" "}
//               <input
//                 type="number"
//                 value={price}
//                 onChange={(e) => setPrice(e.target.value)}
//               />
//             </label>

//             <button type="submit" className="btn-add">Add Vehicle</button>
//           </form>

//           <h3>Current Inventory</h3>
//           {inventory.length > 0 ? (
//             <>
//               <button onClick={handleDeleteSelected} className="btn-delete">
//                 Delete Selected
//               </button>
//               <table className="inventory-table">
//                 <thead>
//                   <tr>
//                     <th>Select</th>
//                     <th>Make</th>
//                     <th>Model</th>
//                     <th>Year</th>
//                     <th>Trim</th>
//                     <th>Color</th>
//                     <th>Transmission</th>
//                     <th>Mileage</th>
//                     <th>VIN</th>
//                     <th>Price</th> {/* Show Price in the table */}
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {inventory.map((car) => {
//                     const isChecked = selectedIds.includes(car.id);
//                     return (
//                       <tr key={car.id}>
//                         <td>
//                           <input
//                             type="checkbox"
//                             checked={isChecked}
//                             onChange={(e) =>
//                               handleCheckboxChange(car.id, e.target.checked)
//                             }
//                           />
//                         </td>
//                         <td>{car.make}</td>
//                         <td>{car.model}</td>
//                         <td>{car.year}</td>
//                         <td>{car.trim}</td>
//                         <td>{car.color}</td>
//                         <td>{car.transmission}</td>
//                         <td>{car.mileage}</td>
//                         <td>{car.vin}</td>
//                         <td>{car.price || ""}</td> {/* Display Price value */}
//                       </tr>
//                     );
//                   })}
//                 </tbody>
//               </table>
//             </>
//           ) : (
//             <p>No vehicles in inventory yet.</p>
//           )}
//         </>
//       )}
//     </div>
//   );
// }

// export default DealerPortal;
import React from 'react';
import { Link } from 'react-router-dom';
import './BottomNav.css';

// React Icons (example icons from AI / FontAwesome / etc.)
import { AiFillHome } from 'react-icons/ai';
import { FaCar } from 'react-icons/fa';
import { FaRobot } from 'react-icons/fa';

const BottomNav = () => {
    return (
        <nav className="bottom-nav">
            <Link to="/">
                <AiFillHome className="icon" />
                <span className="label">Home</span>
            </Link>
            <Link to="/garage">
                <FaCar className="icon" />
                <span className="label">Garage</span>
            </Link>
            <Link to="/AskOrBuy">
                <FaRobot className="icon" />
                <span className="label">AI</span>
            </Link>
        </nav>
    );
};

export default BottomNav;


// import React from 'react';
// import { Link } from 'react-router-dom';
// import './BottomNav.css';

// const BottomNav = () => {
//     return (
//         <nav className="bottom-nav">
//             <Link to="/">
//                 <span className="icon">🏠</span>
//                 <span className="label">Home</span>
//             </Link>
//             <Link to="/garage">
//                 <span className="icon">🚗</span>
//                 <span className="label">Garage</span>
//             </Link>
//             <Link to="/AskOrBuy">
//                 <span className="icon">🤖</span>
//                 <span className="label">AI</span>
//             </Link>
//         </nav>
//     );
// };

// export default BottomNav;

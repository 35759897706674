import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Results from './pages/Results';
import Garage from './pages/Garage';
import DealershipFinder from './components/DealershipFinder';
import Navbar from './components/Navbar';
import BottomNav from './components/BottomNav';
import { loginRequest } from './loginRequest';
import AskOrBuy from './pages/AskOrBuy';
import AskQarbyPage from './pages/AskQarbyPage';
import FindYourCarPage from './pages/FindYourCarPage';
import Footer from './components/Footer';
import { UserProvider, useUser } from './UserContext'; 
import DealerPortal from './pages/DealerPortal';

function AppContent({ msalInstance }) {
    const [account, setAccount] = useState(null);
    const { setUser } = useUser();

    useEffect(() => {
        // Now that redirect is handled in index.js, we can directly get the active account
        const activeAccount = msalInstance.getActiveAccount();
        if (activeAccount) {
            setAccount(activeAccount);
        }
    }, [msalInstance]);

    useEffect(() => {
        if (account && account.idTokenClaims) {
        console.log("ID Token Claims:", account.idTokenClaims);
          const { name, preferred_username, email } = account.idTokenClaims;
          setUser({
            id: account.localAccountId,
            displayName: name || preferred_username || account.username,
            email: email || preferred_username || account.username
          });
        } else {
          setUser(null);
        }
      }, [account, setUser]);
      

    const handleLogin = () => {
        msalInstance.loginRedirect(loginRequest).catch(error => {
            console.error("Login Error:", error);
        });
    };

    const handleLogout = () => {
        msalInstance.logoutRedirect().catch(error => {
            console.error("Logout Error:", error);
        });
    };

    return (
        <Router>
            <Navbar account={account} onLogin={handleLogin} onLogout={handleLogout} />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/askorbuy" element={<AskOrBuy />} />
                <Route path="/askqarby" element={<AskQarbyPage />} />
                <Route path="/findyourcar" element={<FindYourCarPage />} />
                <Route path="/results" element={<Results />} />
                <Route path="/garage" element={<Garage />} />
                <Route path="/dealerships" element={<DealershipFinder />} />
                <Route path="/dealership-portal" element={<DealerPortal />} />
            </Routes>
            <BottomNav />
            <Footer />
        </Router>
    );
}

function App({ msalInstance }) {
    return (
        <UserProvider>
            <AppContent msalInstance={msalInstance} />
        </UserProvider>
    );
}

export default App;

import React from 'react';
// import { Link } from 'react-router-dom';
import '../styles/Home.css';

// Replace this with the actual path to your car image and partner logos
import carImage from '../assets/car-image.jpg'; 
import acuraLogo from '../assets/logos/acura.png';
import teslaLogo from '../assets/logos/tesla.png';
import bmwLogo from '../assets/logos/bmw.png';
import hyundaiLogo from '../assets/logos/hyundai.png';
import carvanaLogo from '../assets/logos/carvana.png';

const Home = () => {
    return (
        <div className="home-container">
            {/* Navigation */}
            <header className="home-header">
              
            </header>

            {/* Hero Section */}
            <section className="home-hero">
                <img src={carImage} alt="Car" className="hero-image" />
            </section>

            {/* Partners Section */}
            <section className="partners-section">
                <h2 className="partners-title">FEW OF OUR PARTNERS</h2>
                <div className="partners-logos">
                    <img src={acuraLogo} alt="Acura Logo" />
                    <img src={teslaLogo} alt="Tesla Logo" />
                    <img src={bmwLogo} alt="BMW Logo" />
                    <img src={hyundaiLogo} alt="Hyundai Logo" />
                    <img src={carvanaLogo} alt="Carvana Logo" />
                </div>
            </section>

            {/* How It Works Section */}
            <section className="how-it-works">
                <h2>HOW DOES <span className="highlight">QARBY</span> WORK?</h2>
                <div className="video-placeholder">
                    {/* This could be a video element or an embed. For now, just a placeholder. */}
                    <div className="play-button">&#9658;</div>
                </div>
            </section>

            
        </div>
    );
};

export default Home;

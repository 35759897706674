import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/AskOrBuy.css';

const AskOrBuy = () => {
  return (
    <div className="macbook-pro">
      <div className="div">
        {/* Top-right user/bubble section */}
        {/* <div className="overlap">
          <div className="rectangle" />
          <div className="ellipse" />
          <div className="text-wrapper">A</div>
        </div> */}

        {/* Example left-hand rectangle (like a logo placeholder) */}
        {/* <div className="rectangle-2" /> */}

        {/* Ask Qarby Card */}
        <div className="overlap-group">
          <div className="CARD-FOR-ASK-QARBY">
            <div className="overlap-group-2">
              <div className="rectangle-3" />
              <div className="rectangle-4" />
              <div className="rectangle-5" />
            </div>
          </div>

          {/* Use Link for navigation */}
          <Link to="/askqarby" className="text-wrapper-2">
            Ask Qarby
          </Link>
          <p className="let-s-chat-about">
            Let's chat about anything cars - maintenance, leasing,
            <br />
            financing, first-time buying, and more
          </p>
        </div>

        {/* Find Your Car Card */}
        <div className="overlap-2">
          <div className="overlap-wrapper">
            <div className="overlap-3">
              <div className="rectangle-6" />
              <div className="rectangle-7" />
              <div className="rectangle-8" />
            </div>
          </div>

          {/* Use Link for navigation */}
          <Link to="/findyourcar" className="text-wrapper-3">
            Find Your Car
          </Link>
          <p className="we-d-help-you-find-a">
            We’d help you find a car that suits your lifestyle,
            <br />
            budget and current situation
          </p>
        </div>
      </div>
    </div>
  );
};

export default AskOrBuy;


// import React from 'react';
// import { Link } from 'react-router-dom';
// import '../styles/AskOrBuy.css';


// const AskOrBuy = () => {
//     return (
//         <div className="chatbot-wrapper">
//             <div className="entrance-cards">
//                 <Link to="/askqarby" className="card">
//                     <h2>Ask Qarby</h2>
//                     <p>Chat about car maintenance, leasing, financing, or buying your first car.</p>
//                 </Link>
//                 <Link to="/findyourcar" className="card">
//                     <h2>Find Your Car</h2>
//                     <p>We’ll help you find the perfect car for your lifestyle and budget.</p>
//                 </Link>
//             </div>
//         </div>
//     );
// };

// export default AskOrBuy;
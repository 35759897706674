import React, { createContext, useState, useContext } from 'react';

// Create the context
const UserContext = createContext(null);

// Custom hook for consuming the user context
export const useUser = () => {
    return useContext(UserContext);
};

// Provider component that wraps your app and makes user available
export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null); 
    // user might initially be null, or after some auth logic you set it to an object like:
    // { id: 'user123', displayName: 'John Doe', email: 'john@example.com' }

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
};

import React, { useState, useCallback, useEffect } from 'react';
import { useUser } from '../UserContext'; 
import './RecommendationsDisplay.css';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../loginRequest';

const RecommendationsDisplay = ({ recommendations, responses, onStartOver }) => {
    const { user } = useUser();
    const { instance, accounts } = useMsal(); 

    const [carListings, setCarListings] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Convert "Lease" or "Buy" or "Not sure" to condition for the backend
    // For example:
    //   Lease => "new"
    //   Buy   => "used"
    //   Not sure => "both"
    const determineCondition = (leaseOrBuy) => {
        if (leaseOrBuy === 'Lease') return 'new';
        if (leaseOrBuy === 'Buy') return 'used';
        return 'both';
    };

    const fetchCarListings = useCallback(async (model) => {
        try {
            const currentYear = new Date().getFullYear();
            // Example: if user wants a car for "3-5 years," we might set minYear. 
            // The code below is just an example; adapt it as you like.
            let minYear = currentYear - 10; // default
            // (If you have user input for how many years old or something, adapt it here.)

            // If user typed a location, use it; else default to "near me"
            const locationParam = responses.location?.trim() || 'near me';

            const queryParams = {
                model,
                location: locationParam,
                budgetMin: responses.budgetMin,
                budgetMax: responses.budgetMax,
                minYear: minYear.toString(),
                purpose: 'general',   // or maybe use usage
                condition: determineCondition(responses.leaseOrBuy),
            };

            const queryString = new URLSearchParams(queryParams).toString();
            const url = `https://carprojfunctionappnew.azurewebsites.net/api/getcarlistings?${queryString}`;
            
            setLoading(true);
            const carListingsResponse = await fetch(url);
            setLoading(false);

            if (!carListingsResponse.ok) {
                const errorData = await carListingsResponse.json();
                throw new Error(errorData.error || 'Failed to fetch car listings');
            }

            // Expect the backend to return something like:
            // {
            //   listings: [ ... ],
            //   totalResults: number,
            //   ...
            // }
            const data = await carListingsResponse.json();

            // Instead of returning top listing, we now return the full array `data.listings`.
            // If the backend only returns a single `listing`, it won't be an array, so check.
            return data.listings || [];
        } catch (error) {
            console.error(`Error fetching listings for ${model}:`, error);
            setError(`Failed to fetch listings for ${model}: ${error.message}`);
            setLoading(false);
            return [];
        }
    }, [responses]);

    useEffect(() => {
        const fetchAllListings = async () => {
            if (!recommendations || recommendations.length === 0) return;
            setError(null);

            const listingsData = {};

            // For each recommended car, fetch the list of matching listings
            for (const car of recommendations) {
                const allListings = await fetchCarListings(car.name);
                listingsData[car.name] = allListings;
            }

            setCarListings(listingsData);
        };

        fetchAllListings();
    }, [recommendations, fetchCarListings]);

    const handleFavorite = async (car) => {
        if (!user || !user.id) {
            alert("Please log in to save favorites.");
            return;
        }
    
        let accessToken;
        try {
            const request = { ...loginRequest, account: accounts[0] };
            const tokenResponse = await instance.acquireTokenSilent(request);
            accessToken = tokenResponse.accessToken;
        } catch (tokenError) {
            console.error("Error acquiring token:", tokenError);
            alert("Could not acquire an access token. Please try logging in again.");
            return;
        }
    
        // Example: parse the 'car.name' into make/model
        const nameParts = car.name.split(' ');
        const make = nameParts[0];
        const model = nameParts.slice(1).join(' ') || 'Unknown';

        // In the new approach, we might or might not have a selected listing from the array.
        // For simplicity, let's just pick the first listing if available:
        const firstListing = carListings[car.name]?.[0];
        const price = firstListing?.priceNumber || 0;

        const username = user.displayName || user.name;
        const email = user.email;
        const imageUrl = car.imageUrl; // from GPT response
        const userResponses = responses; // store all user inputs if desired
    
        try {
            console.log("User in handleFavorite:", user);

            const response = await fetch('https://carprojfunctionappnew.azurewebsites.net/api/savefavorite', {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({
                    userId: user.id,
                    username,
                    email,
                    make,
                    model,
                    price,
                    imageUrl,
                    userResponses
                })
            });
    
            if (!response.ok) {
                let errorMsg = 'Failed to save favorite';
                try {
                    const errorData = await response.json();
                    errorMsg = errorData.error || errorMsg;
                } catch (parseError) {
                    // If no JSON returned
                }
                throw new Error(errorMsg);
            }
    
            const savedFavorite = await response.json();
            alert(`Added to favorites: ${savedFavorite.make} ${savedFavorite.model}`);
        } catch (error) {
            console.error("Error saving favorite:", error.message);
            alert("Error saving favorite. Please try again later.");
        }
    };

    return (
        <div className="recommendations-container">
            <h3>Recommended Cars</h3>

            {error && <div className="error-message">{error}</div>}

            <div className="recommendation-cards">
                {recommendations.map((car, index) => (
                    <div className="recommendation-card" key={index}>
                        <h4>{car.name}</h4>
                        {car.imageUrl && (
                            <img src={car.imageUrl} alt={car.name} className="car-image" />
                        )}
                        <p><strong>Why?</strong> {car.description}</p>
                        <p><strong>What to Look For:</strong> {car.lookFor}</p>

                        {/* Show all listings for this car */}
                        <div className="listings-section">
                            <h5>Nearby {car.name} Listings:</h5>
                            {loading ? (
                                <p className="loading-text">Finding available cars...</p>
                            ) : carListings[car.name] && carListings[car.name].length > 0 ? (
                                carListings[car.name].map((listing, idx) => (
                                    <div className="listing-item" key={idx}>
                                        <h6>{listing.title}</h6>
                                        {/* <p>Price: {listing.priceNumber ? `$${listing.priceNumber}` : 'N/A'}</p> */}
                                        {/* <p>Year: {listing.year || 'N/A'}</p> */}
                                        <a
                                          href={listing.url}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="view-listing-button"
                                        >
                                          View Listing
                                        </a>
                                    </div>
                                ))
                            ) : (
                                <p className="no-listings">No listings found</p>
                            )}
                        </div>

                        <button 
                            className="favorite-button" 
                            onClick={() => handleFavorite(car)}
                        >
                            ♥ Add to Favorites
                        </button>
                    </div>
                ))}
            </div>

            <button onClick={onStartOver} className="start-over-button">
                Start Over
            </button>
        </div>
    );
};

export default RecommendationsDisplay;

// index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./msalConfig";

const msalInstance = new PublicClientApplication(msalConfig);

(async () => {
  // If required by your MSAL version:
  await msalInstance.initialize(); 

  const response = await msalInstance.handleRedirectPromise();
  if (response && response.account) {
    msalInstance.setActiveAccount(response.account);
  } else {
    const activeAccount = msalInstance.getActiveAccount();
    if (activeAccount) {
      msalInstance.setActiveAccount(activeAccount);
    }
  }

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <App msalInstance={msalInstance} />
      </MsalProvider>
    </React.StrictMode>
  );
})();

reportWebVitals();

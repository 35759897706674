import React, { useEffect, useState } from 'react';
import '../styles/Garage.css';
import { useUser } from '../UserContext';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../loginRequest';

const Garage = () => {
    const { user, setUser } = useUser();
    const { instance } = useMsal();
    const [favorites, setFavorites] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showSignInPrompt, setShowSignInPrompt] = useState(false);

    useEffect(() => {
        const fetchFavorites = async () => {
            if (!user || !user.id) {
                // User not signed in, show sign in prompt
                setShowSignInPrompt(true);
                setLoading(false);
                return;
            }

            try {
                const response = await fetch(`https://carprojfunctionappnew.azurewebsites.net/api/getfavorites?userId=${encodeURIComponent(user.id)}`);
                
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Failed to fetch favorites');
                }

                const data = await response.json();
                if (data && data.favorites) {
                    setFavorites(data.favorites);
                } else {
                    setFavorites([]);
                }

                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchFavorites();
    }, [user]);

    const handleSignIn = async () => {
        try {
            // Trigger the MSAL popup login
            const loginResponse = await instance.loginPopup(loginRequest);
    
            // Set the active account so getActiveAccount() returns this account
            instance.setActiveAccount(loginResponse.account);
    
            // Extract user info from loginResponse
            const { account } = loginResponse;
            const name = account.idTokenClaims?.name || account.username;
            const email = account.idTokenClaims?.email || account.username;
    
            setUser({
                id: account.localAccountId,
                displayName: name,
                email: email
            });
    
            setShowSignInPrompt(false);
            setError(null);
            setLoading(true); // to refetch favorites now that we have a signed-in user
        } catch (loginError) {
            console.error("Popup login error:", loginError);
            setError("Failed to sign in. Please try again.");
        }
    };
    

    if (loading) return <div>Loading favorites...</div>;
    if (showSignInPrompt) {
        return (
            <div className="sign-in-prompt">
                <h2>Please Sign In</h2>
                <p>We use email addresses to enable the Garage functionality.</p>
                <button onClick={handleSignIn}>Sign In</button>
            </div>
        );
    }
    if (error) return <div>Error: {error}</div>;
    if (!favorites || favorites.length === 0) return <div>No favorites found.</div>;

    return (
        <div className="favorites-container">
            <h1>Your Saved Cars</h1>
            <div className="car-grid">
                {favorites.map((fav, index) => (
                    <div key={index} className="car-card">
                        <h2>{fav.make} {fav.model}</h2>
                        {fav.imageUrl && (
                            <img 
                                src={fav.imageUrl} 
                                alt={`${fav.make} ${fav.model}`} 
                                style={{ maxWidth: '100%', height: 'auto' }} 
                            />
                        )}
                        <p>Price: ${fav.price}</p>
                        {/* Remove button if needed */}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Garage;
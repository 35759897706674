// carOptions.js

export const carOptions = [
    // 1. ACURA
    {
      make: 'Acura',
      models: [
        'ILX', 'Integra', 'TLX', 'RLX', 'RDX', 'MDX', 'ZDX'
      ]
    },
    // 2. AFEELA (Sony-Honda Mobility brand)
    {
      make: 'AFEELA',
      models: [
        // Official production names TBA; using placeholder(s)
        'Prototype'
      ]
    },
    // 3. ALFA ROMEO
    {
      make: 'Alfa Romeo',
      models: [
        'Giulia', 'Stelvio', 'Tonale', 'Giulietta', '4C', '8C'
      ]
    },
    // 4. ASTON MARTIN
    {
      make: 'Aston Martin',
      models: [
        'DB11', 'Vantage', 'DBS', 'Rapide', 'Vanquish', 'Valkyrie'
      ]
    },
    // 5. AUDI
    {
      make: 'Audi',
      models: [
        'A3', 'A4', 'A6', 'A8', 'Q3',
        'Q5', 'Q7', 'Q8', 'TT', 'R8',
        'e-tron', 'S3', 'S4', 'S6', 'S8'
      ]
    },
    // 6. BENTLEY
    {
      make: 'Bentley',
      models: [
        'Continental GT', 'Bentayga', 'Flying Spur', 'Mulsanne', 'Azure'
      ]
    },
    // 7. BMW
    {
      make: 'BMW',
      models: [
        '3 Series', '5 Series', '7 Series', 'X1', 'X3',
        'X5', 'X7', 'Z4', 'M3', 'M5',
        'i3', 'i8', '4 Series', '2 Series', 'X2',
        'X4', 'X6', 'M4', 'M2', 'Gran Coupe'
      ]
    },
    // 8. BUICK
    {
      make: 'Buick',
      models: [
        'Enclave', 'Encore', 'Envision', 'Regal', 'LaCrosse'
      ]
    },
    // 9. CADILLAC
    {
      make: 'Cadillac',
      models: [
        'Escalade', 'XT4', 'XT5', 'XT6', 'CT4', 'CT5', 'CT6', 'Lyriq'
      ]
    },
    // 10. CHEVROLET
    {
      make: 'Chevrolet',
      models: [
        'Silverado', 'Equinox', 'Malibu', 'Traverse', 'Camaro',
        'Impala', 'Tahoe', 'Suburban', 'Trax', 'Bolt EV',
        'Colorado', 'Express', 'Blazer', 'Volt'
      ]
    },
    // 11. CHRYSLER
    {
      make: 'Chrysler',
      models: [
        '300', 'Pacifica', 'Voyager', 'Crossfire', 'PT Cruiser'
      ]
    },
    // 12. DAEWOO
    {
      make: 'Daewoo',
      models: [
        'Lanos', 'Nubira', 'Leganza', 'Matiz'
      ]
    },
    // 13. DAIHATSU
    {
      make: 'Daihatsu',
      models: [
        'Mira', 'Copen', 'Terios', 'Charade', 'Rocky'
      ]
    },
    // 14. DODGE
    {
      make: 'Dodge',
      models: [
        'Charger', 'Challenger', 'Durango', 'Journey', 'Grand Caravan'
      ]
    },
    // 15. EAGLE
    {
      make: 'Eagle',
      models: [
        'Talon', 'Vision', 'Summit'
      ]
    },
    // 16. FERRARI
    {
      make: 'Ferrari',
      models: [
        '488', '812 Superfast', 'Portofino', 'Roma', 'SF90 Stradale', 'F8 Tributo'
      ]
    },
    // 17. FIAT
    {
      make: 'FIAT',
      models: [
        '500', '500X', '500L', 'Panda', 'Tipo'
      ]
    },
    // 18. FISKER
    {
      make: 'Fisker',
      models: [
        'Karma', 'Ocean', 'Pear'
      ]
    },
    // 19. FORD
    {
      make: 'Ford',
      models: [
        'F-150', 'Explorer', 'Escape', 'Mustang', 'Fusion',
        'Edge', 'Bronco', 'Ranger', 'Expedition', 'EcoSport',
        'Taurus', 'Flex', 'Transit', 'GT', 'Super Duty',
        'Bronco Sport', 'Escape Hybrid', 'Mustang Mach-E', 'F-250', 'F-350'
      ]
    },
    // 20. FREIGHTLINER
    {
      make: 'Freightliner',
      models: [
        'Cascadia', 'M2', 'Sprinter', 'Coronado'
      ]
    },
    // 21. GENESIS
    {
      make: 'Genesis',
      models: [
        'G70', 'G80', 'G90', 'GV70', 'GV80'
      ]
    },
    // 22. GEO
    {
      make: 'Geo',
      models: [
        'Prizm', 'Metro', 'Tracker', 'Storm'
      ]
    },
    // 23. GMC
    {
      make: 'GMC',
      models: [
        'Sierra', 'Canyon', 'Terrain', 'Acadia', 'Yukon', 'Savana'
      ]
    },
    // 24. HONDA
    {
      make: 'Honda',
      models: [
        'Civic', 'Accord', 'CR-V', 'Pilot', 'Fit',
        'HR-V', 'Odyssey', 'Ridgeline', 'Insight', 'Passport',
        'Clarity', 'Element', 'Legend', 'NSX', 'Accord Hybrid',
        'Civic Type R', 'Jazz', 'Vezel', 'Stream'
      ]
    },
    // 25. HUMMER
    {
      make: 'HUMMER',
      models: [
        'H1', 'H2', 'H3', 'EV Pickup', 'EV SUV'
      ]
    },
    // 26. HYUNDAI
    {
      make: 'Hyundai',
      models: [
        'Elantra', 'Sonata', 'Santa Fe', 'Tucson', 'Accent',
        'Kona', 'Palisade', 'Venue', 'Ioniq', 'Nexo',
        'Veloster', 'Santa Cruz', 'Azera', 'Genesis' // older usage
      ]
    },
    // 27. INEOS
    {
      make: 'INEOS',
      models: [
        'Grenadier'
      ]
    },
    // 28. INFINITI
    {
      make: 'INFINITI',
      models: [
        'Q50', 'Q60', 'QX50', 'QX60', 'QX80'
      ]
    },
    // 29. ISUZU
    {
      make: 'Isuzu',
      models: [
        'D-Max', 'MU-X', 'Trooper', 'Rodeo', 'Ascender'
      ]
    },
    // 30. JAGUAR
    {
      make: 'Jaguar',
      models: [
        'XE', 'XF', 'XJ', 'F-Type', 'F-PACE', 'E-PACE', 'I-PACE'
      ]
    },
    // 31. JEEP
    {
      make: 'Jeep',
      models: [
        'Wrangler', 'Grand Cherokee', 'Cherokee', 'Compass', 'Gladiator', 'Renegade'
      ]
    },
    // 32. KIA
    {
      make: 'Kia',
      models: [
        'Soul', 'Seltos', 'Sportage', 'Sorento', 'Telluride',
        'Optima', 'Stinger', 'Niro', 'Rio', 'Cadenza',
        'K5', 'Sedona', 'Carens', 'Venga', 'Ceed'
      ]
    },
    // 33. LAMBORGHINI
    {
      make: 'Lamborghini',
      models: [
        'Aventador', 'Huracán', 'Urus', 'Gallardo', 'Countach', 'Sian'
      ]
    },
    // 34. LAND ROVER
    {
      make: 'Land Rover',
      models: [
        'Range Rover', 'Range Rover Sport', 'Range Rover Evoque', 'Discovery', 'Defender'
      ]
    },
    // 35. LEXUS
    {
      make: 'Lexus',
      models: [
        'IS', 'ES', 'GS', 'LS', 'NX',
        'RX', 'GX', 'LX', 'RC', 'LC',
        'UX'
      ]
    },
    // 36. LINCOLN
    {
      make: 'Lincoln',
      models: [
        'Navigator', 'Aviator', 'Corsair', 'Nautilus', 'MKZ', 'Continental'
      ]
    },
    // 37. LOTUS
    {
      make: 'Lotus',
      models: [
        'Elise', 'Exige', 'Evora', 'Emira', 'Evija'
      ]
    },
    // 38. LUCID
    {
      make: 'Lucid',
      models: [
        'Air', 'Gravity'
      ]
    },
    // 39. MASERATI
    {
      make: 'Maserati',
      models: [
        'Ghibli', 'Quattroporte', 'Levante', 'GranTurismo', 'MC20'
      ]
    },
    // 40. MAYBACH
    {
      make: 'Maybach',
      models: [
        '57', '62', 'S-Class'
      ]
    },
    // 41. MAZDA
    {
      make: 'MAZDA',
      models: [
        'Mazda3', 'Mazda6', 'CX-3', 'CX-5', 'CX-9',
        'CX-30', 'CX-50', 'MX-5 Miata', 'CX-90'
      ]
    },
    // 42. MCLAREN
    {
      make: 'McLaren',
      models: [
        '570S', '720S', '765LT', 'GT', 'P1', 'Senna', 'Artura'
      ]
    },
    // 43. MERCEDES-BENZ
    {
      make: 'Mercedes-Benz',
      models: [
        'C-Class', 'E-Class', 'GLC', 'GLE', 'S-Class',
        'GLA', 'GLB', 'GLS', 'CLA', 'CLS',
        'A-Class', 'B-Class', 'SL-Class', 'AMG GT', 'G-Class',
        'EQC', 'EQA', 'GLS Coupe', 'Maybach S-Class'
      ]
    },
    // 44. MERCURY
    {
      make: 'Mercury',
      models: [
        'Sable', 'Grand Marquis', 'Milan', 'Mountaineer', 'Mariner'
      ]
    },
    // 45. MINI
    {
      make: 'MINI',
      models: [
        'Cooper', 'Cooper S', 'Clubman', 'Countryman', 'Paceman'
      ]
    },
    // 46. MITSUBISHI
    {
      make: 'Mitsubishi',
      models: [
        'Lancer', 'Outlander', 'Eclipse', 'Mirage', 'Montero', 'Pajero'
      ]
    },
    // 47. NISSAN
    {
      make: 'Nissan',
      models: [
        'Altima', 'Sentra', 'Rogue', 'Murano', 'Pathfinder',
        'Maxima', 'Leaf', 'Versa', 'Titan', 'Frontier',
        'Armada', 'Kicks', '370Z', 'Juke', 'NV200'
      ]
    },
    // 48. OLDSMOBILE
    {
      make: 'Oldsmobile',
      models: [
        'Alero', 'Aurora', 'Cutlass', 'Intrigue', 'Silhouette'
      ]
    },
    // 49. PANOZ
    {
      make: 'Panoz',
      models: [
        'Esperante', 'AIV Roadster', 'Avezzano'
      ]
    },
    // 50. PLYMOUTH
    {
      make: 'Plymouth',
      models: [
        'Voyager', 'Neon', 'Prowler', 'Breeze', 'Sundance'
      ]
    },
    // 51. POLESTAR
    {
      make: 'Polestar',
      models: [
        '1', '2', '3', '4'
      ]
    },
    // 52. PONTIAC
    {
      make: 'Pontiac',
      models: [
        'G6', 'G8', 'Grand Prix', 'Firebird', 'Vibe', 'Solstice'
      ]
    },
    // 53. PORSCHE
    {
      make: 'Porsche',
      models: [
        '911', 'Panamera', 'Cayenne', 'Macan', 'Taycan',
        'Boxster', 'Cayman', '918 Spyder'
      ]
    },
    // 54. RAM
    {
      make: 'Ram',
      models: [
        '1500', '2500', '3500', 'ProMaster', 'Chassis Cab'
      ]
    },
    // 55. RIVIAN
    {
      make: 'Rivian',
      models: [
        'R1T', 'R1S'
      ]
    },
    // 56. ROLLS-ROYCE
    {
      make: 'Rolls-Royce',
      models: [
        'Phantom', 'Ghost', 'Wraith', 'Dawn', 'Cullinan', 'Spectre'
      ]
    },
    // 57. SAAB
    {
      make: 'Saab',
      models: [
        '9-3', '9-5', '9-2X', '9-4X', '900'
      ]
    },
    // 58. SATURN
    {
      make: 'Saturn',
      models: [
        'S-Series', 'L-Series', 'Vue', 'Ion', 'Relay', 'Sky'
      ]
    },
    // 59. SCION
    {
      make: 'Scion',
      models: [
        'xB', 'xD', 'tC', 'iA', 'iM', 'FR-S'
      ]
    },
    // 60. SCOUT (Volkswagen's electric truck/SUV brand)
    {
      make: 'Scout',
      models: [
        // Future electric truck & SUV
        'Electric SUV', 'Electric Truck'
      ]
    },
    // 61. smart
    {
      make: 'smart',
      models: [
        'fortwo', 'forfour', 'EQ fortwo', 'EQ forfour'
      ]
    },
    // 62. SRT (Street & Racing Technology)
    {
      make: 'SRT',
      models: [
        'Viper', 'Charger SRT', 'Challenger SRT'
      ]
    },
    // 63. SUBARU
    {
      make: 'Subaru',
      models: [
        'Impreza', 'WRX', 'Forester', 'Outback', 'Legacy',
        'Crosstrek', 'BRZ', 'Ascent'
      ]
    },
    // 64. SUZUKI
    {
      make: 'Suzuki',
      models: [
        'Swift', 'Alto', 'SX4', 'Jimny', 'Vitara', 'Kizashi'
      ]
    },
    // 65. TESLA
    {
      make: 'Tesla',
      models: [
        'Model S', 'Model 3', 'Model X', 'Model Y', 'Cybertruck', 'Roadster'
      ]
    },
    // 66. TOYOTA
    {
      make: 'Toyota',
      models: [
        'Camry', 'Corolla', 'RAV4', 'Highlander', 'Prius',
        'Supra', 'Tacoma', 'Sienna', '4Runner', 'Avalon',
        'Yaris', 'C-HR', 'Venza', 'Land Cruiser', 'Sequoia',
        'Mirai', 'Hilux', 'GR Supra', 'Camry Hybrid', 'Corolla Hybrid'
      ]
    },
    // 67. VINFAST
    {
      make: 'VinFast',
      models: [
        'VF e34', 'VF 5', 'VF 6', 'VF 7', 'VF 8', 'VF 9'
      ]
    },
    // 68. VOLKSWAGEN
    {
      make: 'Volkswagen',
      models: [
        'Golf', 'Polo', 'Passat', 'Jetta', 'Tiguan',
        'Atlas', 'Touareg', 'ID.3', 'ID.4', 'Beetle'
      ]
    },
    // 69. VOLVO
    {
      make: 'Volvo',
      models: [
        'S60', 'S90', 'V60', 'V90', 'XC40',
        'XC60', 'XC90', 'C40'
      ]
    }
  ];
  
import React, { useState, useMemo, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import Select from 'react-select';
import './AskQarby.css';
import { carOptions } from './carOptions.js';

const STATIC_YEARS = [
    2012, 2013, 2014, 2015, 2016, 2017,
    2018, 2019, 2020, 2021, 2022, 2023,
    2024, 2025
];

const AskQarby = ({ onBack }) => {
    const [conversation, setConversation] = useState([]);
    const [currentMessage, setCurrentMessage] = useState('');
    const [isChatLoading, setIsChatLoading] = useState(false);
    const [selectedMake, setSelectedMake] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);

    //   // If you do NOT have year data in carOptions, define a static set of years:

    const getYearOptions = useCallback(() => {
        if (!selectedMake || !selectedModel) return [];
        return STATIC_YEARS.map(year => ({ value: year, label: year.toString() }));
    }, [selectedMake, selectedModel]);

    const getMakeOptions = useCallback(() => {
        return carOptions.map(car => ({
            value: car.make,
            label: car.make
        }));
    }, []);

    const getModelOptions = useCallback(() => {
        if (!selectedMake) return [];
        const selectedCar = carOptions.find(car => car.make === selectedMake.value);
        if (!selectedCar || !selectedCar.models) return [];
        return selectedCar.models.map(modelName => ({
            value: modelName,
            label: modelName
        }));
    }, [selectedMake]);

    const makeOptionsMemo = useMemo(() => getMakeOptions(), [getMakeOptions]);
    const modelOptionsMemo = useMemo(() => getModelOptions(), [getModelOptions]);
    const yearOptionsMemo = useMemo(() => getYearOptions(), [getYearOptions]);

    const handleMakeChange = (selectedOption) => {
        setSelectedMake(selectedOption);
        setSelectedModel(null);
        setSelectedYear(null);
    };

    const handleModelChange = (selectedOption) => {
        setSelectedModel(selectedOption);
        setSelectedYear(null);
    };

    const handleYearChange = (selectedOption) => {
        setSelectedYear(selectedOption);
    };

    const handleSendMessage = async () => {
        if (currentMessage.trim() === '') return;

        // Add the user's new message to local conversation
        setConversation(prev => [...prev, { role: 'user', content: currentMessage }]);
        const userMessage = currentMessage;
        setCurrentMessage('');
        setIsChatLoading(true);

        //     // Build our system prompt without nested backticks
        const systemPrompt = `You are Qarby, an enthusiastic car-obsessed friend who loves explaining automotive issues in everyday language. 
            You’re witty but never condescending. You love to illustrate points with humorous, car-related metaphors.

            Below is an example of how you respond when given a user’s car problem:
            [Example user question: "Whenever I start my SUV, it makes this weird squealing noise. What’s wrong?"]
            [Example assistant answer: "Hey there! Let’s figure out what’s going on. It sounds like your SUV is begging for a new serpentine belt, kind of like a guitar string that’s gone way out of tune..."]

            ${selectedMake && selectedModel && selectedYear
                ? `The user has a ${selectedMake.value} ${selectedModel.value} from ${selectedYear.value}. Consider common issues and recalls for this specific model and year.`
                : 'Consider general automotive best practices and common issues.'
            }

                    Your 5 key goals are:
                    1. Help users solve their car problems
                    2. Provide specific, actionable solutions
                    3. Explain why the problem might be occurring
                    4. Mention safety considerations when relevant
                    5. Suggest when professional help is needed

                    Your tone is friendly, slightly witty, but always professional. 
                    Use a short, warm introduction, such as: "Hey there! Let’s figure out what’s going on."
                    Then proceed with the 5-step format:
                    1. Problem Assessment
                    2. Potential Causes
                    3. Recommended Solutions
                    4. Safety Notes (if applicable)
                    5. Next Steps

                    Stay upbeat and illustrate concepts with fun, car-related analogies whenever possible.
                    `;


        try {
            // Call the Azure Function (instead of OpenAI directly)
            const response = await fetch('https://carprojfunctionappnew.azurewebsites.net/api/openaicall', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    systemPrompt,
                    conversation,
                    userMessage
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            const assistantResponse = data.choices[0].message.content;

            // Update conversation with the AI response
            setConversation(prev => [
                ...prev,
                { role: 'assistant', content: assistantResponse }
            ]);
        } catch (error) {
            console.error('Error:', error);
            setConversation(prev => [
                ...prev,
                {
                    role: 'assistant',
                    content: 'I apologize, but I encountered an error. Please try again later.'
                }
            ]);
        } finally {
            setIsChatLoading(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    return (
        <div className="askqarby-container">
            <div className="askqarby-sidebar">
                <div className="brand">QARBY</div>
                {onBack && (
                    <button className="ios-back-button" onClick={onBack}>← Back</button>
                )}
            </div>

            <div className="askqarby-chat-interface">
                <div className="car-selection">
                    <Select
                        value={selectedMake}
                        onChange={handleMakeChange}
                        options={makeOptionsMemo}
                        placeholder="Select Make"
                        className="car-select"
                        isClearable
                    />

                    <Select
                        value={selectedModel}
                        onChange={handleModelChange}
                        options={modelOptionsMemo}
                        placeholder="Select Model"
                        className="car-select"
                        isClearable
                        isDisabled={!selectedMake}
                    />

                    <Select
                        value={selectedYear}
                        onChange={handleYearChange}
                        options={yearOptionsMemo}
                        placeholder="Select Year"
                        className="car-select"
                        isClearable
                        isDisabled={!selectedMake || !selectedModel}
                    />
                </div>

                <div className="messages">
                    {conversation.map((msg, index) => (
                        <div
                            key={index}
                            className={msg.role === 'user' ? 'user-message' : 'assistant-message'}
                        >
                            <ReactMarkdown>{msg.content}</ReactMarkdown>
                        </div>
                    ))}
                    {isChatLoading && (
                        <div className="assistant-message typing">
                            <p className="typing-indicator">Typing...</p>
                        </div>
                    )}
                </div>

                <div className="chat-input">
                    <input
                        type="text"
                        value={currentMessage}
                        onChange={(e) => setCurrentMessage(e.target.value)}
                        placeholder="Ask any car-related question..."
                        onKeyPress={handleKeyPress}
                    />
                    <button
                        onClick={handleSendMessage}
                        disabled={isChatLoading || !currentMessage.trim()}
                    >
                        Send
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AskQarby;